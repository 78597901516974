// const runSequence = ($transitions, UIService) => {
//   'ngInject';

//   $transitions.onStart({
//     to: (state) => UIService.onStateChange(state)
//   });
// };

const runSequence = ($cookies, $location, $http, Configuration) => {
  'ngInject'
  console.log('----------------- Referoo START ----------------- ')
  // const REFEROO_REFERRED = 'referoo_referred'
  // const REFEROO_REFERRED_VALUE = 'true'
  // const referoo_referred = $cookies.get(REFEROO_REFERRED)
  const ref = $location.search().ref
  // console.log('Referoo > referoo_referred > ', referoo_referred)
  console.log('Referoo > ref > ', ref)

  if (angular.isUndefined(ref) || ref === '' || ref === true) {
    console.log('Referoo > no ref or invalid > do nothing')
  } else {
    console.log('Referoo > post to referoo')
    const payload = {
      shortcode: ref,
      productRef: $location.search().prod,
      customerRef: $location.search().cust,
      customerId: $location.search().cust_id
    }

    $http.post(`${Configuration.apiUrl}/referoo/visits`, payload, {
      withCredentials: true
    }).then(response => {
      console.log('Referoo > post to referoo > success > cookie set', response)
    }, error => {
      console.log('Referoo > post to referoo > error', error)
    })
  }

  // if ((angular.isDefined(referoo_referred) && referoo_referred === REFEROO_REFERRED_VALUE)) {
  //   console.log('Referoo > cookie found > do nothing')
  // } else if (angular.isUndefined(ref) || ref === '' || ref === true) {
  //   console.log('Referoo > no ref or invalid > do nothing')
  // } else {
  //   console.log('Referoo > post to referoo')
  //   const payload = {
  //     shortcode: ref
  //   }

  //   $http.post(`${Configuration.apiUrl}/referoo/visits`, payload, {
  //     withCredentials: true
  //   }).then(response => {
  //     const d = new Date()
  //     const expires = new Date(d.getFullYear() + 1, d.getMonth(), d.getDate())

  //     $cookies.put(REFEROO_REFERRED, REFEROO_REFERRED_VALUE, {
  //       expires: expires
  //     })
  //     console.log('Referoo > post to referoo > success > cookie set', response)
  //   }, error => {
  //     console.log('Referoo > post to referoo > error', error)
  //   })
  // }

   // Process for Tune
  const queryParams = $location.search()
  const { transaction_id, id: partner_name, partner_id, offer_id, source } = queryParams

  if (source === 'tune') {
    console.log('Tune > query params detected > ', queryParams)

    const tunePayload = {
      sessionId: transaction_id || null,
      partnerId: partner_id || null,
      planId: offer_id || null,
      partnerName: partner_name || null
    }

     // Check if at least one of the Tune parameters is present
    if (transaction_id || partner_id || offer_id || partner_name) {
      $http.post(`${Configuration.apiUrl}/referrals/visits/tune`, tunePayload)
        .then(response => {
          console.log('Tune > post to tune > success', response)
        }, error => {
          console.log('Tune > post to tune > error', error)
        })
    }
  }

  console.log('----------------- Referoo END ----------------- ')
}
export default runSequence
