export default {
  CDBL_ENV: 'staging', // will be overriden by webpack
  isProd: false, // will be overriden by webpack
  isBuild: true, // will be overriden by webpack
  apiUrl: 'https://api.staging.codeable.io',
  auth: {
    tokenRenewalInterval: 60 // 1800
  },
  settings: {
    tokenRenewalInterval: 1800, // TODO: deprecate this
    maxAllowedExpertTags: 20
  },
  pusher: {
    appKey: '2276a24d17bae754d49b',
    authEndpoint: 'https://api.staging.codeable.io/pusher/auth'
  },
  intercom: {
    appId: 'sjo2fvk3'
  },
  paypal: {
    env: 'sandbox',
    clientId: 'ASyCCh3JoipM_9ilQX3BYUa8eDhucjmz0T8if8XwyHHWtTgrYDD4V4iQKyVaTU0ds-cqTtWVmfdEmM-H'
  },
  growthbook: {
    apiKey: 'sdk-V6f1tR2rOJyl05u'
  },
  segmentAnalytics: 'cPqI7aDYr6W4kz3t69b7See4tXiMD2Om',
  sentry: {
    dns: 'https://cce4182e0bc140b8ab8dab956a46c9bc@o26974.ingest.sentry.io/107328',
  },
  googleAnalytics: 'G-NGLMQVTJ01',
  googleClientId: '216260975019-iuc3htnvu0mu8u853ndmigvd45qgbb2g.apps.googleusercontent.com'
}
